<template>
    <v-layout class="page" fill-height>
        <div class="conBox step3">
          <ul class="sTab">
            <li v-for="(item, i) in tabList" :key="i">
              <a :class="item.class" @click.prevent="changeTab(i)" v-ripple>{{item.name}}</a>
            </li>
          </ul>
          <ul class="detTab">
            <li class="cobb" :class="{ 'on': curIndex == 0 }" v-show="curIndex == 0" :key="'cobb'">
              <ul class="cobbDiv">
                <li>
                  <div class="contTitW">
                    <p class="contTit">{{ $t('page.result.subTitle.results', {type: getResultType(XRAY_TYPE_SPINE)}) }}</p>
                  </div>
                  <spine-rslt-compo v-if="xrayMap.spine" :xrayMap="xrayMap.spine" /> <!--@loaded="startGuideWhenLoaded"-->
                </li>
                <li class="history">
                  <div class="contTitW">
                    <p class="contTit">{{ $t('page.result.subTitle.history') }}</p>
                    <div class="btnW" style="width:auto;" id="gd_hist_div">
                      <button class="btn solid small" v-ripple @click="historySpinePop = true;"><img src="../../../assets/images/data_check.svg" alt="">{{ $t('page.result.button.history') }}</button>
                      <button class="btn default small" @click="graphSpinePop=true; graphSpineKey+=1;" v-ripple><img src="../../../assets/images/query_stats.svg" alt="">{{ $t('page.result.button.graph') }}</button>
                    </div>
                  </div>

                  <ul class="historyList" v-if="historySpineImageList && historySpineImageList.length > 0">
                    <li class="xrayImg" v-for="(item, i) in historySpineImageList" :key="i">
                      <spine-rslt-compo :xrayMap="item"/>
                    </li>
                  </ul>
                  <div class="emptyBox" v-else v-html="selectHistoryMessage"></div>
                </li>
              </ul>
            </li>
            <li class="bone" :class="{ 'on': curIndex == 1 }" v-show="curIndex == 1" :key="'bone'">
              <ul class="boneDiv">
                <li class="topDiv">
                  <div class="div">
                    <div class="styleTitW">
                      <p class="styleTit">{{ $t('page.result.subTitle.results', {type: getResultType(XRAY_TYPE_HANDS)}) }}</p>
                    </div>
                    <hands-rslt-compo v-if="xrayMap.hands" :title="READING_HANDS_NAME + ' 분석 결과'" :xrayMap="xrayMap.hands"/>
                  </div>
                  <div class="div">
                    <div class="styleTitW">
                      <p class="styleTit">{{ $t('page.result.subTitle.atlas') }}</p>
                    </div>
                    <hands-at-rslt-compo v-if="xrayMap.hands" :xrayMap="xrayMap.hands" :key="atResultKey"/>
                  </div>
                </li>
                <li class="botDiv">
                  <div class="titW">
                    <div class="styleTitW">
                      <p class="styleTit">{{ $t('page.result.subTitle.history') }}</p>
                      <div class="hisBtnW">
                        <button class="btn solid small" @click="historyHandsPop = true;" v-ripple><img src="../../../assets/images/data_check.svg" alt="">{{ $t('page.result.button.history') }}</button>
                        <button class="btn default small" @click="graphHandsPop=true; graphHandsKey+=1;" v-ripple><img src="../../../assets/images/query_stats.svg" alt="">{{ $t('page.result.button.graph') }}</button>
                      </div>
                    </div>
                  </div>
                  <ul class="imgDiv" v-if="historyHandsImageList && historyHandsImageList.length > 0">
                      <hands-rslt-compo v-for="(item,i) in historyHandsImageList" :key="i" :xrayMap="item"/>
                  </ul>
                  <ul class="imgDiv" v-else>
                    <li class="emptyBox">{{ $t('page.result.text.noHistory') }}</li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="result" :class="{ 'on': curIndex === 2 }" v-show="curIndex === 2" :key="'result'" v-if="xrayMap?.spine && xrayMap?.hands">
              <tot-rslt-compo :xrayMap="xrayMap" :xrayNo="xrayNo" />
            </li>
          </ul>
        </div>
        <transition name="fade">
          <history-popup v-if="historySpinePop" :xrayType="XRAY_TYPE_SPINE" :historyNoList="historySpineNoList" :xrayNo="xrayMap?.spine?.xrayNo" @selectHistory="selectHistory" @closePopup="historySpinePop=false;"/>
          <history-popup v-if="historyHandsPop" :xrayType="XRAY_TYPE_HANDS" :historyNoList="historyHandsNoList" :xrayNo="xrayMap?.hands?.xrayNo" @selectHistory="selectHistory" @closePopup="historyHandsPop=false;"/>
          <graph-popup v-if="graphSpinePop" :xrayName="READING_SPINE_NAME" :xrayType="XRAY_TYPE_SPINE" :xrayNo="xrayMap?.spine?.xrayNo" @closePopup="graphSpinePop=false;" :key="graphSpineKey"/>
          <graph-popup v-if="graphHandsPop" :xrayName="READING_HANDS_NAME" :xrayType="XRAY_TYPE_HANDS" :xrayNo="xrayMap?.hands?.xrayNo" @closePopup="graphHandsPop=false;" :key="graphHandsKey" />
        </transition>
    </v-layout>
</template>

<script>
import API_RESULT from '../../../API/reading/result'
import API_HISTORY_IMAGE from '../../../API/reading/historyImage'
import historyPopup from '../../../popup/historyPopup'
import graphPopup from '../../../popup/graphPopup'
import EventBus from '../../../plugins/EventBus'
import { startGuide3 } from '../../../plugins/guide.js';

export default {
  middleware: "auth",
  components: {
    historyPopup,
    graphPopup,
    handsAtRsltCompo: () => import("@/components/handsAtRsltCompo.vue"),
  },
  props: {
  },
  data() {
      return {
        curIndex: this.$route.query.indexProps,
        xrayMap: {},
        historySpinePop: false,
        historyHandsPop: false,
        historySpineNoList: [],
        historySpineImageList: [],
        historyHandsNoList: [],
        historyHandsImageList: [],
        graphSpinePop: false,
        graphHandsPop: false,
        atResultKey: 0,
        graphHandsKey: 0,
        graphSpineKey: 0,
        xrayNo: this.$route.query.xrayNo
      };
  },
  watch: {
    '$i18n.locale': function() {
      this.updateTabList();
    }
  },
  created() {},
  mounted() {
    this.getResult(this.$route.query.xrayNo);
  },
  destroyed() {
    this.initLocalStorage()
  },
  methods: {
    startGuideWhenLoaded(){
      if(this.userInfo.useGuide < 3){
        this.$nextTick(() => {
          const guideStarter = startGuide3();
          guideStarter(); 
        });
      }
    },
    async getResult(no){
      let res = await API_RESULT.request(no);
      if (res.isSuccess) {
        if(res.spine) this.roundAngles(res.spine);
        const resMap = {
          'spine': res.spine,
          'hands': res.hands
        }
        this.xrayMap = resMap;
        if (this.xrayMap?.spine) this.roundAngles(this.xrayMap?.spine);
        if (this.xrayMap?.hands && !this.xrayMap?.spine) this.curIndex = 1;
        EventBus.emit('reading_end',this.xrayMap);
        this.setTab();
        this.atResultKey += 1;
      }else{
        this.showPopup(res.errorMsg, res.status);
      }
    },
    updateTabList() {
      this.setTab();
    },
    setTab() {
      this.tabList.forEach(tab => {
        tab.class = '';
      });
      if (this.tabList.length > 0) this.tabList[this.curIndex].class = 'on';
    },
    changeTab(index) {
      this.tabList.forEach(item => { item.class = ''; });
      this.tabList[index].class = "on";
      this.curIndex = index;
    },
    selectHistory(xrayType, event){
      if(!event || (event && event.length == 0)){
        this.historySpineNoList = [];
        this.historyHandsNoList = [];
        this.historySpineImageList = [];
        this.historyHandsImageList = [];
      }else{
        if(xrayType == this.XRAY_TYPE_SPINE){
          this.historySpineNoList = event;
          this.getSpineHistoryImage(this.XRAY_TYPE_SPINE,this.historySpineNoList);
        }else if(xrayType == this.XRAY_TYPE_HANDS){
          this.historyHandsNoList = event;
          this.getSpineHistoryImage(this.XRAY_TYPE_HANDS,this.historyHandsNoList);
        }
      }
      this.historySpinePop = false;
      this.historyHandsPop = false;
    },
    async getSpineHistoryImage(xrayType,historyNoList){
      let res = await API_HISTORY_IMAGE.request(xrayType, historyNoList);
      if(res.isSuccess){
        if(xrayType == this.XRAY_TYPE_SPINE){
          this.historySpineImageList = res.list;
          for (let image of this.historySpineImageList) {
            this.roundAngles(image);
          }
        } else if(xrayType == this.XRAY_TYPE_HANDS){
          this.historyHandsImageList = res.list;
        }
      }else{
        this.showPopup(res.errorMsg, res.status);
      }
    },
    getResultType(type){
      switch(type){
          case this.XRAY_TYPE_SPINE:
              return this.$t('common.analysis.type.spine');
          case this.XRAY_TYPE_HANDS:
              return this.$t('common.analysis.type.hands');
      }
    }
  },
  computed: {
    selectHistoryMessage() {
      return this.$t('page.result.text.selectHistory');
    },
    tabHeader() {
      return {
        spine: {name: this.$t('common.analysis.type.spine'), class: '', trans:'common.analysis.type.spine'},
        hands: {name: this.$t('common.analysis.type.hands'), class: '', trans:'common.analysis.type.hands'},
        tot: {name: this.$t('common.analysis.type.both'), class: '', trans:'common.analysis.type.both'}
      }
    },
    tabList() {
      const list = [];
      if (this.xrayMap.spine) list.push({...this.tabHeader.spine});
      if (this.xrayMap.hands) list.push({...this.tabHeader.hands});
      if (this.xrayMap.spine && this.xrayMap.hands) list.push({...this.tabHeader.tot});
      if (list.length > 0) list[0].class = 'on';
      return list;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../sass/app.scss";
.v-application {
  .blue, .red {background-color: transparent !important; border-color: transparent !important;}
  .totalReportView {position: absolute; right: 40px; top: 40px;}
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sTab li{
  transition: opacity 0.5s;
}
.sTab li:hover{
  opacity: 0.8;
}

button{
  transition: box-shadow 0.5s, opacity 0.5s;
}
button:hover{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
}

.cobbDiv .contTitW{
  position:relative;
  height: auto;
  min-height: 32px;
  .contTit{
    position: relative;
    padding-left: 16px;
    color: var(--color-s80);
    font-size: 20px;
    font-weight: 600;
  }
}

.bone .boneDiv .botDiv .titW{margin-bottom: 0;}
.bone .boneDiv .styleTitW{width: 100%; position: relative; height: auto; min-height: 32px; margin-bottom: 16px; display: flex; align-items: center; justify-content: space-between;}
.bone .boneDiv  .styleTit{margin-bottom: 0;}
</style>